import React from 'react'

import clsx from 'clsx'
import { Typography, Box, Drawer, List, Divider, IconButton } from '@mui/material';

import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'
import FloatingMenu from './parts/menu/floating'
import CollapseMenu from './parts/menu/collapse'

import './styles.scss'


const SideBar = (props) => {
  const {
    isDrawerOpen,
    items,
    setDrawer,
    navigate,
    selectedItem,
    selectedSubitem,
    welcome_user_name,
    mode,
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleMenuClose(event) {
    setAnchorEl(null);
  }
  function handleMenuToggle(event) {
    if (!!anchorEl && event.currentTarget.id === anchorEl.id)
      setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  }
  const handleDrawerToggle = (event) => {
    setAnchorEl(null);
    setDrawer(!isDrawerOpen)
  };
  return (
    <Drawer
      className='drawer'
      position='fixed'
      variant="permanent"
      sx={{ zIndex: 'tooltip' }}
    >
      <Box sx={{ height: 1, bgcolor: 'background.menu' }}>
        <Box sx={{ display: 'flex', minHeight: '50px', alignItems: 'center' }}>
          <IconButton onClick={handleDrawerToggle}>
            {isDrawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>

          {!!isDrawerOpen && <Typography sx={{ color: 'primary.main', fontSize: 'subtitle.fontSize', fontWeight: 600 }}>
            {welcome_user_name}
          </Typography>}
        </Box>
        <Divider />
        <List>
          {
            _.map(items, (item) => (
              isDrawerOpen ?
                <CollapseMenu
                  key={item.id}
                  item={item}
                  enabled={(Boolean(anchorEl) && anchorEl.id === item.id) || (!Boolean(anchorEl) && item.id === selectedItem)}
                  selected={item.id === selectedItem}
                  handleMenuToggle={handleMenuToggle}
                  handleOnClick={navigate}
                  selectedSubitem={selectedSubitem}
                  mode={mode}
                />
                :
                <FloatingMenu
                  key={item.id}
                  item={item}
                  enabled={Boolean(anchorEl) && anchorEl.id === item.id}
                  handleMenuOpen={handleMenuOpen}
                  handleMenuClose={handleMenuClose}
                  navigate={navigate}
                  anchorEl={anchorEl}
                  handleOnClick={navigate}
                  selected={item.id === selectedItem}
                  selectedSubitem={selectedSubitem}
                  mode={mode}
                />
            ))
          }

        </List>
      </Box>
    </Drawer >
  )
}

export default React.memo(SideBar)
