import React from 'react'
import Component, { Title, SubTitle, LinkView } from './component'
import withContainer from './container'

export {
  Component,
  withContainer
}

export default React.memo(withContainer(Component))
export { Title, SubTitle, LinkView }

