import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Button, Typography } from '@mui/material'

const ShortcutHelper = ({ handleHotkey, shortcuts, ...props }) => <>
  <Box justifyContent='center' alignItems='center' >
    {
      _.map(shortcuts, (row, indexRow) =>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {_.map(row, ({ hotkey, action }, index) =>
            <Box sx={{ br: 1, boxShadow: 6, m: 1 }}>
              <Button key={`${indexRow}-${index}`} onClick={() => handleHotkey(hotkey)}>
                <Box sx={{ display: 'block' }}>
                  <Box>
                    <Typography variant={'button'} sx={{ fontWeight: 'bold', fontSize: 15 }}>
                      {_.isString(hotkey) ? hotkey : `${_.head(hotkey)} + ${I18n.t(`hotkey.key.${_.last(hotkey)}`)}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant={'button'} sx={{ fontSize: 15 }} >
                      {I18n.t(`hotkey.action.${action}`)}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Box>
          )}
        </Box>
      )
    }
  </Box >
</>

export default React.memo(ShortcutHelper)