import React from 'react'

import { IconButton, Icon, Popover, Box, ListItem } from '@mui/material'
import SubMenu from '../../submenu'


const FloatingMenu = ({ item, enabled, anchorEl, handleMenuOpen, handleMenuClose, handleOnClick, selected, selectedSubitem, mode }) =>
  <ListItem
    id={item.id}
    key={item.id}
    onMouseOver={handleMenuOpen}
    onMouseLeave={handleMenuClose}
    sx={{
      justifyContent: 'center',
      p: '4px',
      color: !!selected ? 'primary.light' : mode === 'dark' ? 'white' : 'primary.dark',
      fontSize: selected ? 33 : 25,
      '&:hover': { fontSize: 33, color: 'primary.light' }
    }}
  >
    <IconButton sx={{ color: 'inherit', fontSize: 'inherit' }} disableRipple>
      <Icon sx={{ color: 'inherit', fontSize: 'inherit' }}> {item.icon}</Icon>
    </IconButton>
    <Popover
      id={`menu-${item.id}`}
      anchorEl={anchorEl}
      open={enabled}
      onClose={handleMenuClose}
      disableRestoreFocus
      elevation={8}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          ml: '1px',
          boxShadow: '10px 5px 5px rgb(0 0 0 / 20%)',
          borderRadius: 0
        }
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{ zIndex: 1200, pointerEvents: 'none' }}
    >
      <SubMenu key={item.id} items={item.subitems} handleOnClick={handleOnClick} selectedItem={selectedSubitem} mode={mode} />
    </Popover>
  </ListItem >

export default React.memo(FloatingMenu)
