import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Link, Box, Typography } from '@mui/material'

import TypeUtils from '@front/volcanion/utils/type'
import ViewSelector from '@front/volcanion/components/ViewSelector'
import Actions from '@abra/elements/Button/Form'

const Title = React.memo(ViewSelector)
const SubTitle = React.memo(ViewSelector)
const LinkView = React.memo(ViewSelector)

const SubTitleComponent = ({ subTitle, ...props }) => <Typography sx={{ fontSize: 'h6.fontSize', mr: 1 }}> {subTitle} </Typography>

const LinkComponent = ({ onLinkClick, linkText, ...props }) => <Box hidden={!linkText} >
  <Typography variant={'h5'} sx={{ fontSize: 'h6.fontSize' }}>
    <Link target='_blank' onClick={onLinkClick}>
      {linkText}
    </Link>
  </Typography>
</Box >

const FormHeader = props => {
  const {
    isManagingRecord,
    labelKey,
    labelParams,
    label,
    record_client_id,
    children,
    enableActions = true,
    ...rest
  } = props

  const titleChildren = TypeUtils.allByType(children, Title)

  const subTitleChildren = _.isEmpty(TypeUtils.allByType(children, SubTitle))
    ? [<SubTitleComponent {...props} />]
    : TypeUtils.allByType(children, SubTitle)

  const linkChildren = _.isEmpty(TypeUtils.allByType(children, LinkView))
    ? [<LinkComponent {...props} />]
    : TypeUtils.allByType(children, LinkView)

  return <>
    <Grid container alignItems='flex-end'>
      <Grid item xs={12}>
        <Box sx={{ boxShadow: 6, borderRadius: 1, p: 1, bgcolor: 'primary.main', width: 'fit-content', display: 'flex', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ color: 'white', letterSpacing: 3, }}>
            {!!label ? label : I18n.t(`${labelKey}.${!isManagingRecord ? 'create' : 'detail'}`, { record_client_id, ...labelParams })}
          </Typography>
          {_.map(titleChildren, (titleChild, key) => <ViewSelector{...TypeUtils.getProps(titleChild)} key={key}>{titleChild}</ViewSelector>)}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex' }}>
          {_.map(subTitleChildren, (subTitleChild, key) => <ViewSelector{...TypeUtils.getProps(subTitleChild)} key={key}>{subTitleChild}</ViewSelector>)}
          {_.map(linkChildren, (linkChild, key) => <ViewSelector{...TypeUtils.getProps(linkChild)} key={key}>{linkChild}</ViewSelector>)}
        </Box>
      </Grid>
      <Grid item xs hidden={!enableActions}>
        <Actions {...props} />
      </Grid>
    </Grid>
  </>

}


export default React.memo(FormHeader)
export {
  Title,
  SubTitle,
  LinkView
}
