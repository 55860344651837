import React from 'react'
import clsx from 'clsx'

import { Box, Typography, Toolbar, IconButton, Badge, AppBar, Grid } from '@mui/material'

import { Notifications } from '@mui/icons-material'
import { CDNSVG } from '@front/volcanion'
import Menu from './Menu/'

import './styles.scss'

const TopBar = ({ navigateNotifications, navigateJobs, incident_count, job_count, children, version, isDrawerOpen, ...props }) =>
  <AppBar position="fixed" color="primary" className={clsx('appbar', { ['shift']: isDrawerOpen })} sx={{ zIndex: 1300 }}>
    <Toolbar className={clsx('toolbar', { ['shift']: isDrawerOpen })} >
      <Grid container>
        <Grid container item xs={8} alignItems='center'>
          {children}
        </Grid>
        <Grid container item justifyContent='flex-end' xs={4} alignItems='center'>
          <IconButton onClick={navigateJobs} aria-label={`show ${job_count} new notifications`}>
            <Badge badgeContent={job_count} color='error' sx={{ m: 0 }}>
              <CDNSVG style={{ fill: 'white' }} name={'dispatch-in_coming'} />
            </Badge>
          </IconButton>
          <IconButton onClick={navigateNotifications} aria-label={`show ${incident_count} new notifications`}>
            <Badge badgeContent={incident_count} color='error' sx={{ m: 0 }}> <Box color='white'> <Notifications /> </Box> </Badge>
          </IconButton>
          <Menu {...props} />
          <Typography variant={'h4'} sx={{ ml: 1, color: 'white' }}> | </Typography>
          <Typography variant={'h5'} sx={{ ml: 1, mr: 2, color: 'white' }}> {version || 'local'} </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar >

export default React.memo(TopBar)
