import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'
const withContainer = Component => props => {
  const navigate = hooks.useNavigate()
  const version = hooks.useVersion()

  const [incident_count] = hooks.useModelSearchCount('event', 'get', {
    initial_filter: {},
    forced_filter: { active: true },
    sort: ['producedAt ASC'],
    watch_type: 'count'
  })

  const [job_count] = hooks.useModelSearchCount('job', 'get', {
    initial_filter: {},
    forced_filter: {
      dispatchedAt: { '!': null },
      status: ['created', 'standby'],
    },
    watch_type: 'count',
  })

  const navigateNotifications = () => navigate('/incident')
  const navigateJobs = () => navigate('/dispatch')

  const mergedProps = {
    version,
    incident_count,
    job_count,
    navigateNotifications,
    navigateJobs,
  }
  return <Component {...mergedProps} {...props} />

}

export default withContainer
